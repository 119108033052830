import React from "react";
import { graphql } from 'gatsby';
import styles from './events.module.scss';

import Layout from "../components/layout";
import SEO from "../components/seo";
import InnerPageHero from "../components/innerPageHero";
import {EventCard} from "../components/cards";
import Contact from "../components/contact"

import heroImg from "../images/events-hero.jpg";

const EventsPage = ({ data }) => (
  <Layout>
    <SEO title="CareAdvisors | Social Care Automation for Community Health" />
    <InnerPageHero
      title="Events"
      subtitle="Learn more about events hosted by CareAdvisors and our industry partners"
      heroImg={heroImg}
      color="orange"
    />

    <div className="container">
    <div className={styles.events}>
    {
      data.allWpEvent.nodes
        .sort(
          (left, right) => (
            Date.parse(right.eventData.eventDate) - Date.parse(left.eventData.eventDate)
          )
        )
        .map((event) => {
        return (
          <EventCard
            title={event.title}
            content={event.content}
            buttonText={event.eventData.ctaText}
            buttonLink={event.eventData.ctaLink}
            image={event.featuredImage ? event.featuredImage.node : null}
            eventPrice={event.eventData.eventPrice}
            eventLocation={event.eventData.eventLocation}
            locationName={event.eventData.eventLocationName}
            eventDate={event.eventData.eventDate}
            eventTime={event.eventData.eventTime}
            eventTimezone={event.eventData.eventTimezone}
          />
        )
      })
    }
  </div>
    </div>

    <Contact />

  </Layout>
)

export const data = graphql`
query EventsQuery {
    allWpEvent {
      nodes {
        id
        title
        content
        eventData {
          ctaLink
          ctaText
          eventDate
          eventPrice
          eventTime
          eventTimezone
          eventLocation {
            city
            country
            countryShort
            state
            stateShort
            streetAddress
            streetName
            streetNumber
          }
          eventLocationName
        }
        featuredImage {
            node {
              srcSet
              sizes
            }
        }
      }
    }
  }  
`

export default EventsPage
